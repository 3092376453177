const useSearchResultsSEO = (
  departmentVal,
  action,
  area,
  bedroom,
  bathVal,
  minprice,
  maxprice,
  statusVal,
  property_type,
  amenities,
  minsqftVal,
  maxsqftVal,
  newdevelopmentsVal,
  completionVal
) => {
  // departmentVal,searchtypeVal,areaVal,bedVal,minpriceVal,maxpriceVal,statusVal,buildingval,amenities,minsqftVal,maxsqftVal,newdevelopmentsVal

  const convertSlugToCapitalizedText = slug => {
    if (!slug) return ""
    return slug
      .split("-")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(" ")
  }

  const getAreasText = areas => {
    let text = ""
    if (!areas) return text

    if (!Array.isArray(areas)) areas = [areas]

    if (areas.length > 0) {
      text = `${areas
        .map(area => convertSlugToCapitalizedText(area))
        .join(", ")
        .replace(/,([^,]*)$/, " and$1")}`
    }

    return text
  }

  let pagetitle = ""
  let pageh1 = ""
  let pagemetadesc = ""
  let introcopy = ""

  let myactin = "for sale"
  if (action == "lettings") myactin = "to rent"

  let myarea = getAreasText(area)

  let myproperty = "Properties"
  if (property_type) {
    myproperty =
      property_type?.charAt(0)?.toUpperCase() +
      property_type?.substring(1).replaceAll("-", " ") +
      "s"
  }
  // if(propertyTypeVal === "new_developments") {
  //     myproperty = "New homes"
  // }
  if (departmentVal === "commercial") {
    myproperty = "Commercial properties"
    if (property_type) {
      myproperty =
        "Commercial " +
        property_type.charAt(0)?.toUpperCase() +
        property_type.substring(1).replaceAll("-", " ") +
        "s"
    }
  }
  if (departmentVal === "Offplan") {
    myproperty = "Off Plan properties"
    if (property_type) {
      myproperty =
        "Off Plan " +
        property_type.charAt(0)?.toUpperCase() +
        property_type.substring(1).replaceAll("-", " ") +
        "s"
    }
  }

  function formatBedroomsForSale(arr) {
    if (arr.length === 0) return ''; // handle empty array case

    if (arr.length === 1 && arr[0]==='0') return ` with ${arr[0]} `;
    if (arr.length === 1) return ` with ${arr[0]} bedroom `;
    
    const lastItem = arr.pop(); // remove the last item
    return ` with ${arr.join(', ')} and ${lastItem?.replace('7','7+')} bedrooms`;
  }
  function formatBathroomsForSale(arr) {
    if (arr.length === 0) return ''; // handle empty array case
    if (arr.length === 1) return  bedroom?.length>0 ? ` also with ${arr[0]?.replace('7','7+')} bathroom `: ` with ${arr[0]?.replace('7','7+')} bathroom `;
    
    const lastItem = arr.pop(); // remove the last item
    return  bedroom?.length>0 ? ` also with ${arr.join(', ')} and ${lastItem?.replace('7','7+')} bathrooms`: ` with ${arr.join(', ')} and ${lastItem?.replace('7','7+')} bathrooms`;
  }
  let mybedroom = ""
  let isOnlyStudio = Array.isArray(bedroom) ? '0':''
  let bedRoomValue= isOnlyStudio ? isOnlyStudio?.split('-or'):bedroom.split('-or-')
  if((departmentVal==="residential"  || departmentVal === "Offplan")){
     if(bedroom?.length>0){
      const splitBeds = isOnlyStudio ? isOnlyStudio?.split('-or') : bedroom.split('-or-')
      mybedroom = formatBedroomsForSale(splitBeds)?.replace('0', 'Studio')
     } 
  }
  else{
  if (bedroom > 0) {
    if (departmentVal === "Offplan") {
      mybedroom = ` with ${bedroom}+ bedrooms`
    } else {
      mybedroom = ` with ${bedroom} bedrooms`
    }
  } else if (bedroom === 0) {
    mybedroom = ` with studio`
  }
  }

  let mybathroom = ""
  let bathRoomValue = bathVal.split('-or-')
  // if(bathVal>0){
  //   mybathroom = ` with ${bathVal} bathrooms`
  // }

  if(bathVal?.length>0){
    const splitBath = bathVal.split('-or-')
    mybathroom = formatBathroomsForSale(splitBath)
   } 

  let feature = ""
  if (typeof amenities !== "string" && amenities?.length > 0) {
    // const ama =
    let text = amenities?.map((list, index) => {
      return `${list}
              ${index === amenities?.length - 1 ? "" : ""}`
    })
    feature = ` with features ${amenities
      ?.toString()
      ?.replace(/-/g, " ")
      ?.replace(/,/g, " and ")}.`
  }

  let mypricestr = ""
  if (minprice > 0 && maxprice > 0) {
    mypricestr = ` between ${currencyFormat(minprice)} and ${currencyFormat(
      maxprice
    )}`
  } else if (maxprice > 0) {
    mypricestr = ` below ${currencyFormat(maxprice)}`
  } else if (minprice > 0) {
    mypricestr = ` above ${currencyFormat(minprice)}`
  }

  let mysqftstr = ""
  if (minsqftVal > 0 && maxsqftVal > 0) {
    mysqftstr = ` between ${minsqftVal}sqft and ${maxsqftVal}sqft`
  } else if (maxsqftVal > 0) {
    mysqftstr = ` below ${maxsqftVal}sqft`
  } else if (minsqftVal > 0) {
    mysqftstr = ` above ${minsqftVal}sqft`
  }
  let completionStr = ""
  if (completionVal) {
    completionStr = ` that are ${completionVal}`
  }

  const currentpath=typeof window!=="undefined"?window.location.pathname:""

  if(currentpath==="/properties/for-sale/in-dubai/"){
    pagetitle=`Properties for Sale in Dubai - Buy your dream Property`
  }
  else if(currentpath==="/properties/for-sale/in-dubai/type-apartment/"){
    pagetitle=`Apartments for sale in Dubai - Buy Apartments in Dubai`
  }
  else if(currentpath==="/properties/for-sale/in-dubai/type-villa/"){
    pagetitle=`Villas for Sale in Dubai - Buy Villas in Dubai`
  }
  else if(currentpath === "/properties/for-sale/in-dubai/type-townhouse/"){
    pagetitle=`Townhouse for Sale in Dubai - Buy townhouses in Dubai`
  }
  else if(currentpath === "/properties/for-sale/in-dubai-marina/"){
    pagetitle=`Properties for Sale in Dubai Marina`
  }
  else if(currentpath === "/properties/for-sale/in-dubai-marina/type-apartment/"){
    pagetitle=`Apartments for sale in Dubai Marina`
  }
  else if(currentpath === "/properties/for-sale/in-dubai-marina/type-villa/"){
    pagetitle=`Villas for sale in Dubai Marina`
  }
  else if(currentpath === "/properties/to-rent/in-dubai/"){
    pagetitle=`Properties for rent in Dubai - Rent Property in Dubai`
  }
  else if(currentpath === "/properties/to-rent/in-dubai/type-apartment/"){
    pagetitle=`Apartments for rent in Dubai - Rent Apartment in Dubai`
  }
  else if(currentpath === "/properties/to-rent/in-dubai/type-villa/"){
    pagetitle=`Villas for villa in Dubai - Rent Villa in Dubai`
  }
  else if(currentpath === "/properties/to-rent/in-dubai/type-townhouse/"){
    pagetitle=`Townhouse for rent in Dubai - Rent a townhouse in Dubai`
  }
  else if(currentpath === "/properties/to-rent/in-dubai/type-penthouse/"){
    pagetitle=`Penthouse for rent in Dubai - Rent a Penthouse`
  }
  else if (currentpath==="/commercial-properties/for-sale/in-dubai/"){
    pagetitle=`Commercial Properties for Sale in Dubai`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-office-space/"){
    pagetitle=`office space for Sale in Dubai - Buy your own office`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-retail/"){
    pagetitle=`Retail spaces for Sale in Dubai - Shops for Sale`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-warehouse/"){
    pagetitle=`Warehouse for Sale in Dubai - Commercial Warehouses`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-factory/"){
    pagetitle=`Factory Space for Sale in Dubai`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-commercial-villa/"){
    pagetitle=`Commercial villas for sale in Dubai`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-showroom/"){
    pagetitle=`Showroom for sale in Dubai - Type Commercial`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-full-floor/"){
    pagetitle=`Showrooms for sale in Dubai`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-whole-building/"){
    pagetitle=`Commercial Buildings For Sale  in Dubai`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-land/"){
    pagetitle=`Commercial Lands for sale in Dubai`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-labour-camp/"){
    pagetitle=`Labour camps for sale in Dubai`
  }
  else if(currentpath === "/commercial-properties/for-sale/in-dubai/type-staff-accomodation/"){
    pagetitle=`Staff accomodations for sale in Dubai`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/"){
    pagetitle=`Commercial Properties for rent in Dubai`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-office-space/"){
    pagetitle=`office space for rent in Dubai - Rent office in Dubai`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-retail/"){
    pagetitle=`Retail spaces for rent in Dubai - Shops for rnt in Dubai`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-warehouse/"){
    pagetitle=`Warehouse for rent in Dubai - Commercial Warehouses`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-factory/"){
    pagetitle=`Factory Space for rent in Dubai`
  }
  else if(currentpath === "commercial-properties/to-rent/in-dubai/type-commercial-villa/"){
    pagetitle=`Commercial villas for rent in Dubai`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-showroom/"){
    pagetitle=`Showroom for rent in Dubai - Type Commercial`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-full-floor/"){
    pagetitle=`Showrooms for rent in Dubai`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-whole-building/"){
    pagetitle=`Commercial Buildings for rent in Dubai`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-land/"){
    pagetitle=`Commercial Lands for rent in Dubai`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-labour-camp/"){
    pagetitle=`Labour camps for rent in Dubai`
  }
  else if(currentpath === "/commercial-properties/to-rent/in-dubai/type-staff-accomodation/"){
    pagetitle=`Staff accomodations for rent in Dubai`
  }
  

  else{
  pagetitle = `${myproperty} ${myactin} ${
    myarea ? "in " + myarea : ""
  }${mybedroom}${mybathroom}${mypricestr}${feature}`
  }

  pageh1 =
    departmentVal === "Offplan"
      ? `${myarea} ${myproperty} ${mybedroom}${mypricestr}${feature}${completionStr}`
      : `${myproperty} ${myactin} ${
          myarea ? "in " + myarea : ""
        }${mybedroom}${mybathroom}${mypricestr}${feature}`

  // if (departmentVal === "Offplan") {
  //   pagemetadesc = `Explore our latest collection of ${myproperty} ${myactin} ${
  //     myarea ? "in " + myarea : ""
  //   }? ${
  //     process.env.GATSBY_SITE_NAME
  //   } are here to help you in your properties journey.`
  // } else {
  //   pagemetadesc = `Looking for ${myproperty} ${myactin} ${
  //     myarea ? "in " + myarea : ""
  //   }? Contact ${process.env.GATSBY_SITE_NAME} ${
  //     myarea ? "in " + myarea : ""
  //   } to find the right Properties for you.`
  // }

  // if (completionVal) {
  //   pageh1 = `${myproperty} ${myactin} that are ${completionVal}`
  //   pagetitle = `${myproperty} ${myactin} that are ${completionVal}`
  // }

  pagemetadesc = `Looking for ${myproperty} ${myactin} ${
    myarea ? "in " + myarea : ""
  }? Contact ${process.env.GATSBY_SITE_NAME} ${
    myarea ? "in " + myarea : ""
  } to find the right Properties for you.`

  if (area != process.env.GATSBY_DEFAULT_AREA.toLowerCase()) {
    pagemetadesc = `Looking for ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }? Contact ${process.env.GATSBY_SITE_NAME} ${
      myarea ? "in " + myarea : ""
    } to find the right Properties for you.`
  }
  if (property_type) {
    pagemetadesc = `Explore our latest collection of ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }. Properties experts from ${
      process.env.GATSBY_SITE_NAME
    } are here to help.`
  }
  if (bedRoomValue?.length > 0 && bedRoomValue[0]!=='') {
    pagemetadesc = `Explore ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybedroom} here. Contact our expert estate agents today to get assistance in finding the right Properties ${
      myarea ? "in " + myarea : ""
    }.`
  }
  if (bathRoomValue?.length > 0 && bathRoomValue[0]!=='') {
    pagemetadesc = `Explore ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybathroom} here. Contact our expert estate agents today to get assistance in finding the right Properties ${
      myarea ? "in " + myarea : ""
    }.`
  }
  if (mypricestr) {
    pagemetadesc = `Locate the right ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mypricestr} with ${
      process.env.GATSBY_SITE_NAME
    }. Book an appointment with one of our Properties experts ${myactin}.`
  }
  if (completionVal) {
    pagemetadesc = `Identify the ${myproperty} ${myactin} that are ${completionVal}. Book a viewing with ${process.env.GATSBY_SITE_NAME} to avail expert help in knowing more about the properties that interests you.`
  }
  // if (
  //   mypricestr &&
  //   (myproperty != "Properties" ||
  //     myproperty != "Off Plan properties" ||
  //     myproperty != "Commercial properties")
  // ) {
  //   pagemetadesc = `Check out the latest collection of ${myproperty} ${myactin} ${
  //     myarea ? "in " + myarea : ""
  //   }${mypricestr} with ${process.env.GATSBY_SITE_NAME} ${myarea ? "in " + myarea : ""}.`
  // }
  if (mypricestr && bedRoomValue?.length > 0) {
    pagemetadesc = `Explore the list of ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybedroom}${mypricestr} with ${
      process.env.GATSBY_SITE_NAME
    } and request a viewing for the Properties that interests you.`
  }
  if (mypricestr && bathRoomValue?.length > 0) {
    pagemetadesc = `Explore the list of ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybathroom}${mypricestr} with ${
      process.env.GATSBY_SITE_NAME
    } and request a viewing for the Properties that interests you.`
  }
  // if (
  //   (myproperty != "Properties" ||
  //     myproperty != "Off Plan properties" ||
  //     myproperty != "Commercial properties") &&
  //     bedRoomValue.length > 0
  // ) {
  //   pagemetadesc = `Find the latest collection of ${myproperty} ${myactin} ${
  //     myarea ? "in " + myarea : ""
  //   }${mybedroom}. Contact ${
  //     process.env.GATSBY_SITE_NAME
  //   } in ${myarea}, to arrange a viewing.`
  // }
  if (mypricestr && completionVal) {
    pagemetadesc = `Check out the latest collection of ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mypricestr} that are ${completionVal} with haus & haus in ${
      myarea ? "in " + myarea : ""
    }.`
  }
  if (
    (myproperty != "Properties" ||
      myproperty != "Off Plan properties" ||
      myproperty != "Commercial properties") &&
      bedRoomValue.length > 0 &&
    mypricestr
  ) {
    pagemetadesc = `Explore ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybedroom}${mypricestr} available with ${
      process.env.GATSBY_SITE_NAME
    }. Contact one of our estate agents for assistance in finding your Properties ${
      myarea ? "in " + myarea : ""
    }.`
  }

  if (
    (myproperty != "Properties" ||
      myproperty != "Off Plan properties" ||
      myproperty != "Commercial properties") &&
      bedRoomValue.length > 0 &&
    mypricestr &&
    completionVal
  ) {
    pagemetadesc = `Explore ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybedroom} with that are ${completionVal} ${mypricestr} available with ${
      process.env.GATSBY_SITE_NAME
    }. Contact one of our real estate agents for assistance in finding your properties in ${
      myarea ? "in " + myarea : ""
    }.`
  }

  introcopy = `Explore our ${pageh1.replace(
    "Properties",
    "Properties"
  )}. For more information about Properties ${myactin} ${
    myarea ? "in " + myarea : ""
  }, please <a href='/contact/'>get in touch</a> with ${
    process.env.GATSBY_SITE_NAME
  }.`

  return {
    pagetitle,
    pageh1,
    pagemetadesc,
    introcopy,
  }
}

export default useSearchResultsSEO


const currencyFormat = (num, currency = "AED ", withSpace = false) => {
  var filterNum = filterNumber(num)
  if (!filterNum) {
    return `${currency} 0`
  }
  if (withSpace) {
    return `${currency} ${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  } else {
    return `${currency}${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  }
}

const filterNumber = str => {
  if (!str) return 0
  str = str.toString()
  return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

const numberFormat = num => {
  if (!num) return 0
  return new Intl.NumberFormat("en-US", {}).format(num)
}
